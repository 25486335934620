@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-white;
  display: flex;
  flex-direction: column;
}

#duolingo-icon {
  filter: brightness(0);
}

.active {
  @apply bg-gray-50;
}

.project-card {
  @apply snap-center rounded-xl p-5 mr-5 min-w-[300px] max-w-[300px] lg:min-w-[400px] lg:max-w-[400px];
}

#project-hero-1 {
  background-image: url('../public/images/proyectoColisiones.jpg');
  background-size: auto 120%;
  background-repeat: no-repeat;
}

#project-hero-2 {
  background-image: url('../public/images/proyectoCamara.jpg');
  background-size: auto 120%;
  background-repeat: no-repeat;
}

#project-hero-3 {
  background-image: url('../public/images/proyectoNovacamp.jpg');
  background-size: auto 120%;
  background-repeat: no-repeat;
}

#project-hero-4 {
  background-image: url('../public/images/football2.jpeg');
  background-size: auto 120%;
  background-repeat: no-repeat;
}

#project-hero-5 {
  background-image: url('../public/images/unsplash_gradient_2.avif');
  background-size: auto 120%;
  background-repeat: no-repeat;
}
